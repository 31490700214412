import React, { useEffect, useState } from 'react';
import './navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from './../assets/logo-file/logo-transparent-png.png';
import { faRefresh, faWallet } from '@fortawesome/free-solid-svg-icons';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../config/firebase';

const NavbarTop = () => {
    const [walletBalance, setWalletBalance] = useState('-');

    useEffect(() => {
        let unsubscribe;

        const fetchUserProfile = async () => {
            try {
                const email = auth.currentUser.email.toLowerCase();

                const q = query(collection(db, "userProfile"), where("emailId", "==", email));
                unsubscribe = onSnapshot(q, (querySnapshot) => {
                    if (querySnapshot.empty) {
                        console.log("No such document!");
                    } else {
                        querySnapshot.forEach((doc) => {
                            console.log(doc.id, " => ", doc.data());
                            setWalletBalance(doc.data().wallet);
                        });
                    }
                });
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        if (auth.currentUser) {
            fetchUserProfile();
        } else {
            console.log("No authenticated user.");
        }

        // Cleanup the listener on component unmount
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const handleRefreshClick = () => {
        // Since onSnapshot already provides real-time updates,
        // this function can simply log a message or be removed
        console.log('Refreshed');
    };

    return (
        <nav className="nav-wrapper">
            <div className="logoContainer">
                <img src={logo} alt="Company Logo" />
            </div>
            <div className="walletContainer">
                <div className="walletBalancewithIcon">
                    <FontAwesomeIcon icon={faWallet} />
                    {walletBalance}
                </div>
                <FontAwesomeIcon icon={faRefresh} onClick={handleRefreshClick} />
            </div>
        </nav>
    );
};

export default NavbarTop;
